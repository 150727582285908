import { Component,OnInit, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { WINDOW } from '@ng-toolkit/universal';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { environment } from 'src/environments/environment';
import * as $ from 'jquery';
import { urlencoded } from 'express';
import { NgOneTapService } from 'ng-google-one-tap';
import { WebsiteService } from '../app/_services/website.service';
import { StorageService, LOCAL_STORAGE } from 'ngx-webstorage-service';
// import {CookieService} from 'ngx-cookie-service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'trinkaWeb';
  redirectURL = '';
  googleRedirectURL = '';
  userdetails;
  downTimeBanner: boolean = true;
  // private cookie_name='';
  // private all_cookies:any='';
  
  constructor(
    @Inject(WINDOW) private window: Window,
    private onetap: NgOneTapService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(LOCAL_STORAGE) public storage: StorageService,
    private web: WebsiteService,
    //private cookieService:CookieService 
    ) { }
    
    credentials ="";
    ngOnInit(){
      //this.setCookie();
      if (isPlatformBrowser(this.platformId)){
        // this.cookie_name=this.cookieService.get('name');
        // this.all_cookies=this.cookieService.getAll();  // get all cookies object
        

        $.getScript('../../../assets/js/wisepop.js', function(){});
        this.redirectURL = '';
        this.onetap.tapInitialize(); //Initialize OneTap, At intial time you can pass config  like this.onetap.tapInitialize(conif) here config is optional.
        this.onetap.promtMoment.subscribe(res => {  // Subscribe the Tap Moment. following response options all have self explanatory. If you want more info pls refer official document below attached link.
           res.getDismissedReason(); 
           res.getMomentType();
           res.getNotDisplayedReason();
           res.getSkippedReason();
           res.isDismissedMoment();
           res.isDisplayed();
           res.isNotDisplayed();
           res.isSkippedMoment();
        });

        this.onetap.oneTapCredentialResponse.subscribe(res => { // After continue with one tap JWT credentials response.
            console.log(res);
            localStorage.setItem('credentials', res.credential);
            console.log(btoa(res.credential));
            this.credentials = btoa(res.credential);
            var ssoURL = this.OneTapReturnUrl(7); 
            debugger;
            console.log("Enago", ssoURL);
            this.storage.set("meta", {});
            window.location.href = ssoURL+"&credentials="+ this.credentials;
          });
          this.onetap.authUserResponse.subscribe(res => {  // Use Auth validation by using google OAuth2 apis. Note: this one for testing and debugging purpose.
            this.userdetails = res;
            // console.log("Meta", res);
          });
          // if(this.IsCookieExists){
          //   this.cookiesContainer = false;
          // } else {
          //   this.cookiesContainer = true;
          // }
      }
    }
    tapcancel() {
      if (isPlatformBrowser(this.platformId)){
        this.onetap.cancelTheTap();
      }
    }
 
    
    OneTapReturnUrl(value) {
      if (isPlatformBrowser(this.platformId)){
        var country = localStorage.getItem("country")? localStorage.getItem("country"):"en";
        // var myToken = localStorage.getItem("credentials")? localStorage.getItem("credentials"):"credential";
        // this.redirectURL = "/connect/authorize/callback?client_id=Trinka&response_type=code&redirect_uri="+encodeURIComponent("https://"+environment.social_redirectURL)+"&scope=openid api1 offline_access&state=5d0ffa7470bc46b38fa465ae35599c22&entityid=1&ApplicationID=3&SubDomainMappingID=38"
        // this.googleRedirectURL = environment.enago_social_redirect_url + value + "&returnUrl=" + encodeURIComponent(btoa(this.redirectURL))
        // + "&applicationId=3&clientRegType=8;
        // console.log("URL: ", this.googleRedirectURL);
        var redirectURL = "/connect/authorize/callback?client_id=Trinka&response_type=code&redirect_uri=" + environment.social_redirectURL + "&scope=openid api1 offline_access&state=5d0ffa7470bc46b38fa465ae35599c22&entityid=1&ApplicationID=3&SubDomainMappingID=38"
        var url = environment.enago_social_redirect_url + value + "/" + encodeURIComponent(btoa(redirectURL))
      + "/3/8/" + country
        return url;
      }
      
    }

  onActivate(event) {
    if (isPlatformBrowser(this.platformId)) {
      this.window.scroll(0, 0);
    }
  }

  isShow: boolean;
  topPosToStartShowing = 100;
  

  @HostListener('window:scroll')
  checkScroll() {
    if (isPlatformBrowser(this.platformId)) {

      const scrollPosition = this.window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

      if (scrollPosition >= this.topPosToStartShowing) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    }
  }
  gotoTop() {
    if (isPlatformBrowser(this.platformId)) {

      this.window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
  currentDate = new Date();
  // cookiesContainer: boolean = false;
  // IsCookieExists: boolean = this.cookieService.check('Trinka AI Cookies Policy');
  
  // setCookie(){
  //   this.cookieService.set('Trinka AI Cookies Policy','Trinka AI is a next-gen grammar correction and language enhancement writing assistant designed for academic and technical writing.', 365);
  //   this.cookiesContainer = false;
  // }
  // declineCookies(){
  //   this.cookieService.delete('Trinka AI Cookies Policy');
  //   this.cookiesContainer = false;
  // }

  downTime() {
    this.downTimeBanner = false;
  }
}