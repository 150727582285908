import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject, Renderer2 } from '@angular/core';
import { isPlatformBrowser, isPlatformServer, DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import * as $ from 'jquery';
import { LinkService } from '../../../_services/langhfre.service';
import { ActivatedRoute, Router } from '@angular/router';
import { WebsiteService } from 'src/app/_services/website.service';
import { LoadScriptService } from 'src/app/_services/load-script.service';
declare function ire(string,{}): any;
@Component({
  selector: 'app-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.scss']
})
export class CreditsComponent implements OnInit {
  country: string;
  encodedData: any;
  decodedData: any;
  premiumDecodedData: any;
  paymentModeOption: any;
  premiumUserpaymentModeOption: any;
  twentyPackFinalAmount: any;
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private LinkService: LinkService,
    private route: ActivatedRoute,
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    public paymentService: WebsiteService,
    public loadScript: LoadScriptService
  ) { }
  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem("country-trinka") == null || localStorage.getItem("country-trinka") == "") {
        this.loadGeoAPIScript();
      }
    }
    this.titleService.setTitle('Get the flexibility to use the features of Trinka with Credits');
    this.metaTagService.updateTag({ name: 'description', content: "Many additional features of Trinka consume credits. You can utilize these credits to use these features and buy more credits as needed." });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Get the flexibility to use the features of Trinka with Credits' });
    this.metaTagService.updateTag({ property: 'og:description', content: "Many additional features of Trinka consume credits. You can utilize these credits to use these features and buy more credits as needed." });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/features/credits' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/CreditInformation.png' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Get the flexibility to use the features of Trinka with Credits' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Many additional features of Trinka consume credits. You can utilize these credits to use these features and buy more credits as needed." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/CreditInformation.png' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/features/credits' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/features/credits' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/features/credits' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/features/credits' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/features/credits' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/features/credits' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/features/credits' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/features/credits' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/features/credits' });

    if (isPlatformBrowser(this.platformId)) {
      // $.getScript('../../../assets/js/particles.min.js', function(){});
      //$.getScript('../../../assets/js/wisepop.js', function () { });

      this.country = localStorage.getItem("country-trinka") ? localStorage.getItem("country-trinka") : "en";
      console.log(this.country);
      this.route.params.subscribe(params => {
        this.encodedData = params['id'];
        this.paymentService.getPlans(this.encodedData,3).subscribe(resp => {
          if (resp['message'] == 'success') {
            this.decodedData = resp['data']
            this.paymentModeOption = this.decodedData;
            // console.log("Country is: ",this.country);
            console.log("Data", this.decodedData);
            // console.log("Final Amount: ",this.decodedData[2].final_amount);
            // var twentyPack = this.decodedData[3].amount;
            // this.twentyPackFinalAmount = twentyPack / this.decodedData[3].discount * 100;
            // console.log(twentyPack);
            // console.log("twentyPack Final Amount", this.twentyPackFinalAmount);
          }
        });
      });

      this.route.params.subscribe(params => {
        this.encodedData = params['id'];
        this.paymentService.premiumGetPlan(this.encodedData,3).subscribe(resp => {
          if (resp['message'] == 'success') {
            this.premiumDecodedData = resp['data']
            this.premiumUserpaymentModeOption = this.premiumDecodedData;
            // console.log("Country is: ",this.country);
            // console.log("Premium user Data: ", this.premiumDecodedData);
            // console.log("Final Amount: ",this.decodedData[2].final_amount);
            // var twentyPack = this.decodedData[3].amount;
            // this.twentyPackFinalAmount = twentyPack / this.decodedData[3].discount * 100;
            // console.log("twentyPack Final Amount", this.twentyPackFinalAmount);
          }
        });
      });
    }
    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `
    {"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Trinka AI","item":"https://www.trinka.ai/"},{"@type":"ListItem","position":2,"name":"Trinka Features","item":"https://www.trinka.ai/features/"},{"@type":"ListItem","position":3,"name":"Trinka AI Credits","item":"https://www.trinka.ai/features/credits"}]}
    `;

    this._renderer2.appendChild(this._document.head, script);

    let script1 = this._renderer2.createElement('script');
    script1.type = `application/ld+json`;
    script1.text = `
    {"@context":"https://schema.org","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"Are credit purchases refundable?","acceptedAnswer":{"@type":"Answer","text":"Credit purchases cannot be refunded. Purchased credits remain with you until you use them."}},{"@type":"Question","name":"What happens to my free credits if I upgrade to Premium?","acceptedAnswer":{"@type":"Answer","text":"When you upgrade to Premium, your credit balance will reset to 5, regardless of unused free credits."}},{"@type":"Question","name":"What happens to my purchased credits if I upgrade to Premium?","acceptedAnswer":{"@type":"Answer","text":"Your purchased credits remain with you when you upgrade, and you can use them any time you want."}},{"@type":"Question","name":"Is there a limit on credit purchase?","acceptedAnswer":{"@type":"Answer","text":"You can buy as many credits as you want, there is no limit on credit purchases."}}]}
    `;
    this._renderer2.appendChild(this._document.head, script1);
    ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
  }

  loadGeoAPIScript() {
    if (isPlatformBrowser(this.platformId)) {
      var scriptName = "geoIP"
      this.loadScript.loadScript(scriptName, '//geoip-js.com/js/apis/geoip2/v2.1/geoip2.js')
        .then(() => {
          console.log(`${scriptName} loaded successfully`);
          var onSuccess = function (location) {
            localStorage.setItem("country-trinka", location['country']['iso_code'])
          };

          var onError = function (error) {
            localStorage.setItem("country-trinka", "IN")
          };
          try {
            //@ts-ignore
            geoip2.city(onSuccess, onError);
          } catch (error) {
            console.log(error)
          }
        })
        .catch(() => {
          console.error(`Failed to load ${scriptName}`);
        });
    }
  }

  perMonth: any;
  perMonthAfterDis: any;
  calculatePlan(finalAmt, credits) {    
    this.perMonthAfterDis = finalAmt / credits
    return parseFloat(this.perMonthAfterDis).toFixed(2);
  }
  calculateDiscount(annualAmt, finalAmt, credits) {
    let actualAmt = (annualAmt * credits) / (2)
    let perMonthAfterDis: any = ((actualAmt - finalAmt) * 100) / (actualAmt);
    return parseFloat(perMonthAfterDis).toFixed(0);
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      $.getScript('../../../../assets/js/home_client_slider.js', function () { });
      $('#closedPopup, #wordPluginPopup').click(function () {
        $('iframe.youtubeVideoForTryDemo').remove()
      });

      $('#VewiVideoSection').click(function () {
        if (!$('#iframe').length) {
          $('#iframeHolder').html('<iframe class="youtubeVideoForTryDemo" id="iframe" src="https://www.youtube.com/embed/5KsBh4PQUPI" width="100%" height="450"></iframe>');
        }
      });

    }
  }

  

}
