<div class="top-tools-tab-mobile" id="tabs-tools" role="tablist">
    <a [routerLink]="['/grammar-checker']" role="tab" aria-selected="false" tabindex="-1" id="tab-grammar">
        <img src="../../../../assets/images/menu-icons/grammar-checker.svg" alt="grammar-checker">
        <p>Grammar Checker</p>
    </a>
    <a [routerLink]="['/paraphrasing-tool']" role="tab" aria-selected="false" tabindex="-1" id="tab-paraphraser">
        <img src="../../../../assets/images/menu-icons/pharaphraser.svg" alt="Paraphraser">
        <p>Paraphraser</p>
    </a>
    <a [routerLink]="['/ai-content-detector']" role="tab" aria-selected="false" class="active" tabindex="-1" id="tab-ai-detector">
        <img src="../../../../assets/images/menu-icons/ai-detector.svg" alt="AI Detector">
        <p>AI Detector</p>
    </a>
    <a [routerLink]="['/academic-phrasebank']" role="tab" aria-selected="true"  tabindex="0" id="tab-phrasebank">
        <img src="../../../../assets/images/menu-icons/phrasebank.svg" alt="Academic Phrasebank">
        <p>Academic Phrasebank</p>
    </a>
</div>


<section class="banner-section aiContentDetector">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="banner-title">
                    <div class="toolLinkingTabs">
                        <ul>
                            <li>
                                <a [routerLink]="['/grammar-checker']" routerLinkActive="active">
                                    <img class="grammarCheckerIcons" src="../../../assets/icons/GrammarChecker.svg" alt="grammar-checker">
                                    <img class="grammarCheckerActiveIcons" src="../../../assets/icons/GrammarCheckerActive.svg" alt="grammar-checker">
                                    <span>Grammar checker</span>
                                </a>
                            </li>
                            <li>
                                <a  [routerLink]="['/paraphrasing-tool']" routerLinkActive="active">
                                    <img class="grammarCheckerIcons" src="../../../assets/icons/ParaphrasingTool.svg" alt="paraphrasing tool">
                                    <img class="grammarCheckerActiveIcons" src="../../../assets/icons/ParaphrasingToolActive.svg" alt="paraphrasing tool">
                                    <span>Paraphrasing tool</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/ai-content-detector']" routerLinkActive="active">
                                    <img class="grammarCheckerIcons" src="../../../assets/icons/AIContentDetector.svg" alt="AI Content Detector">
                                    <img class="grammarCheckerActiveIcons" src="../../../assets/icons/AIContentDetectorActive.svg" alt="AI Content Detector">
                                    <span>AI Content Detector</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/academic-phrasebank']" routerLinkActive="active">
                                    <img class="grammarCheckerIcons" src="../../../assets/icons/AcademicPhraseBank.svg" alt="academic-phrasebank">
                                    <img class="grammarCheckerActiveIcons" src="../../../assets/icons/AcademicPhraseBankActive.svg" alt="academic-phrasebank">
                                    <span>Academic PhraseBank</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="title"><h1>AI Content Detector</h1></div>
                    <p>Safeguard content integrity by detecting AI-generated text from LLMs like ChatGPT, GPT-4, Bard, Bing Chat instantly.</p>
                    <p>Use Trinka AI Content Detector and our advanced system will quickly identify any AI-generated content with accuracy.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h3>Detect AI-Generated Content</h3>
                        <div class="sessionsLeftInfo">
                            <ul>
                                <li *ngIf="usersCurrentAvailableSessionCount > -1">
                                    <span [ngClass]="usersCurrentAvailableSessionCount === 0 ? 'red-txt': ''">{{usersCurrentAvailableSessionCount}}/10 sessions remaining today</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="left-side">
                            <div class="form-group" [ngClass]="[showWordLimitErr && wordLimitErrMsg.includes('500') ? 'show-max-overlay' : '']">
                                <div class="editor-wrapper scollBarAI" [ngClass]="[showErrorModal ? 'hide-all-content' : showLoadingState ? 'blur-content' : aiContentScore > 0 ? 'error-txt' : '']">
                                    <span *ngIf="inputWordCount" class="cross-icon-times" (click)="clearEditors();"></span>
                                    <div [contentEditable]="!showLoadingState && aiContentScore === 0 && humanContentScore === 0 && !showErrorModal" id="inputEditor"
                                        placeholder="Type or paste your text in English (Minimum 100 words)" class="editor" (input)="onInputEditorChange($event.target.innerText)">
                                    </div>
                                    <div class="error-container" *ngIf="showErrorModal">
                                        <div class="error-txt-container">
                                            <span class="danger-icon"></span>
                                            <span class="error-txt">An error occurred, please try again</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="limit-snackbar" [ngClass]="wordLimitErrHeading.includes('100') ? 'min-limit-snackbar' : 'max-limit-snackbar'" *ngIf="showWordLimitErr">
                                    <h3>
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18.4645 14.3459L13.248 4.95619C12.5471 3.69282 11.5771 3 10.5094 3C9.44161 3 8.47166 3.69282 7.77069 4.95619L2.55419 14.3459C1.89397 15.5441 1.82062 16.6933 2.35042 17.5981C2.88022 18.5028 3.92352 19 5.29285 19H15.7259C17.0952 19 18.1385 18.5028 18.6683 17.5981C19.1981 16.6933 19.1247 15.5359 18.4645 14.3459ZM9.89805 8.70555C9.89805 8.37137 10.1752 8.09424 10.5094 8.09424C10.8435 8.09424 11.1207 8.37137 11.1207 8.70555V12.7809C11.1207 13.1151 10.8435 13.3923 10.5094 13.3923C10.1752 13.3923 9.89805 13.1151 9.89805 12.7809V8.70555ZM11.0881 15.8049C11.0473 15.8375 11.0066 15.8701 10.9658 15.9027C10.9169 15.9353 10.868 15.9598 10.8191 15.9761C10.7702 16.0005 10.7213 16.0168 10.6642 16.025C10.6153 16.0331 10.5583 16.0413 10.5094 16.0413C10.4605 16.0413 10.4034 16.0331 10.3463 16.025C10.2974 16.0168 10.2485 16.0005 10.1996 15.9761C10.1507 15.9598 10.1018 15.9353 10.0529 15.9027C10.0122 15.8701 9.97141 15.8375 9.93065 15.8049C9.78394 15.65 9.69428 15.4381 9.69428 15.2262C9.69428 15.0143 9.78394 14.8023 9.93065 14.6475C9.97141 14.6149 10.0122 14.5823 10.0529 14.5497C10.1018 14.5171 10.1507 14.4926 10.1996 14.4763C10.2485 14.4519 10.2974 14.4356 10.3463 14.4274C10.4523 14.403 10.5664 14.403 10.6642 14.4274C10.7213 14.4356 10.7702 14.4519 10.8191 14.4763C10.868 14.4926 10.9169 14.5171 10.9658 14.5497C11.0066 14.5823 11.0473 14.6149 11.0881 14.6475C11.2348 14.8023 11.3244 15.0143 11.3244 15.2262C11.3244 15.4381 11.2348 15.65 11.0881 15.8049Z" fill="#FFBD4A"/>
                                        </svg>
                                        {{wordLimitErrHeading}}
                                    </h3>
                                    <p [innerHTML]="wordLimitErrMsg" *ngIf="wordLimitErrMsg"></p>
                                    <span class="limit-modal-close" aria-label="close" (click)="hideLimitError()">
                                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.4082 6.66472C17.7614 6.31148 17.7614 5.73877 17.4082 5.38553C17.0549 5.0323 16.4822 5.0323 16.129 5.38553L11.3414 10.1731L6.55388 5.38553C6.20064 5.0323 5.62793 5.0323 5.27469 5.38553C4.92146 5.73877 4.92146 6.31148 5.27469 6.66472L10.0622 11.4523L5.27469 16.2398C4.92146 16.593 4.92146 17.1658 5.27469 17.519C5.62793 17.8722 6.20064 17.8722 6.55388 17.519L11.3414 12.7315L16.129 17.519C16.4822 17.8722 17.0549 17.8722 17.4082 17.519C17.7614 17.1658 17.7614 16.593 17.4082 16.2398L12.6206 11.4523L17.4082 6.66472Z" fill="#414E62"/>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            
                        </div>

                    </div>
                    <div class="card-footer" [ngClass]="inputWordCount > 0 ? '' : 'hide-box-shadow'">
                        <div class="word-count-outer" *ngIf="!showLoadingState && !showErrorModal">
                            <div class="counter">
                                <span class="count" [ngClass]="inputWordCount > 500 ? 'red-txt': inputWordCount > 0 ? 'black-txt' : ''">{{ inputWordCount }}/500 words</span>
                            </div>
                        </div>
                        <div class="button-wrap">
                            <button *ngIf="!aiContentScore && !humanContentScore" (click)="sendTextForAIDetection()" [ngClass]="[showLoadingState ? 'loaderStateBtn' : '']" [disabled]="inputWordCount === 0 || isSubmitBtnDisabled || showLoadingState">
                                <span *ngIf="showLoadingState" class="animated-loader"></span>
                                <span>{{showLoadingState ? 'Checking' : showErrorModal ? 'Reload' : 'Check'}}</span>
                            </button>
                            <div class="ai-generated-txt-btn" *ngIf="aiContentScore">
                                AI Generated Text ({{aiContentScore.toFixed(2)}}%)
                            </div>
                            <div class="human-generated-txt-btn" *ngIf="humanContentScore">
                                Human Generated Text ({{humanContentScore.toFixed(2)}}%)
                            </div>
                            <span class="like-dislike-container" *ngIf="humanContentScore || aiContentScore">
                                <span class="feedback-like-icon" (click)="openFeedbackModal(true)"></span>
                                <span class="feedback-dislike-icon" (click)="openFeedbackModal(false)"></span>
                            </span>
                        </div>
                    </div>
                    <div class="ftContent" *ngIf="humanContentScore">
                        <div class="human-generated-txt-content">
                            <h6>Score explanation:</h6>
                            <span class="information-icon-green">
                                <span class="information-icon-content-outer">
                                    <span class="information-icon-content-score">
                                        The score reflects our model's confidence in predicting that the content scanned was produced by any of the LLMs such as ChatGPT/GPT-X. The score indicates that {{humanContentScore.toFixed(2)}}% of your text is Human generated. This score was computed through sentence-level analysis, where we discerned AI-generated content within each sentence.
                                        <!-- This indicates that {{humanContentScore.toFixed(2)}}% of your text is Human generated. The result has been calculated by analyzing all sentences and determining the AI content composition in each.  -->
                                    </span>
                                    <span class="information-icon-content-constant">
                                        Our system is tailored to excel in predicting AI-generated content in English. It's worth noting that accuracy levels for other languages may not be on par with this proficiency.                                      
                                    </span>
                                </span>
                            </span>
                        </div>
                    </div>
                    <div class="ftContent" *ngIf="aiContentScore">
                        <div class="human-generated-txt-content">
                            <h6>Score explanation:</h6>
                            <span class="information-icon-red">
                                <span class="information-icon-content-outer">
                                    <span class="information-icon-content-score">
                                        The score reflects our model's confidence in predicting that the content scanned was produced by any of the LLMs such as ChatGPT/GPT-X. The score indicates that {{aiContentScore.toFixed(2)}}% of your text is AI generated. This score was computed through sentence-level analysis, where we discerned AI-generated content within each sentence.
                                        <!-- This indicates that {{aiContentScore.toFixed(2)}}% of your text is AI generated. The result has been calculated by analyzing all sentences and determining the AI content composition in each.  -->
                                    </span>
                                    <span class="information-icon-content-constant">
                                        Our system is tailored to excel in predicting AI-generated content in English. It's worth noting that accuracy levels for other languages may not be on par with this proficiency.
                                    </span>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="disclaimerBox">
                    <a href="#howtoUseModal" data-toggle="modal" data-target="#howtoUseModal">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M11.6849 8.99945C11.6849 10.4845 10.4849 11.6845 8.99994 11.6845C7.51494 11.6845 6.31494 10.4845 6.31494 8.99945C6.31494 7.51445 7.51494 6.31445 8.99994 6.31445C10.4849 6.31445 11.6849 7.51445 11.6849 8.99945Z" stroke="#7A28A0" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M9.00013 15.2025C11.6476 15.2025 14.1151 13.6425 15.8326 10.9425C16.5076 9.88504 16.5076 8.10754 15.8326 7.05004C14.1151 4.35004 11.6476 2.79004 9.00013 2.79004C6.35263 2.79004 3.88513 4.35004 2.16763 7.05004C1.49263 8.10754 1.49263 9.88504 2.16763 10.9425C3.88513 13.6425 6.35263 15.2025 9.00013 15.2025Z" stroke="#7A28A0" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg> Steps to use
                    </a>
                    <a href="#disclaimerModal" data-toggle="modal" data-target="#disclaimerModal">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M9 0.937499C13.4475 0.9375 17.0625 4.5525 17.0625 9C17.0625 13.4475 13.4475 17.0625 9 17.0625C4.5525 17.0625 0.9375 13.4475 0.937501 9C0.937501 4.5525 4.5525 0.937499 9 0.937499ZM9 15.9375C12.825 15.9375 15.9375 12.825 15.9375 9C15.9375 5.175 12.825 2.0625 9 2.0625C5.175 2.0625 2.0625 5.175 2.0625 9C2.0625 12.825 5.175 15.9375 9 15.9375Z" fill="#7A28A0"/>
                            <path d="M9 7.6875C9.3075 7.6875 9.5625 7.9425 9.5625 8.25L9.5625 12C9.5625 12.3075 9.3075 12.5625 9 12.5625C8.6925 12.5625 8.4375 12.3075 8.4375 12L8.4375 8.25C8.4375 7.9425 8.6925 7.6875 9 7.6875Z" fill="#7A28A0"/>
                            <path d="M9 5.24984C9.0975 5.24984 9.195 5.27234 9.285 5.30984C9.375 5.34734 9.4575 5.39984 9.5325 5.46734C9.6 5.54234 9.6525 5.61734 9.69 5.71484C9.7275 5.80484 9.75 5.90234 9.75 5.99984C9.75 6.09734 9.7275 6.19484 9.69 6.28484C9.6525 6.37484 9.6 6.45734 9.5325 6.53234C9.4575 6.59984 9.375 6.65234 9.285 6.68984C9.105 6.76484 8.895 6.76484 8.715 6.68984C8.625 6.65234 8.5425 6.59984 8.4675 6.53234C8.4 6.45734 8.3475 6.37484 8.31 6.28484C8.2725 6.19484 8.25 6.09734 8.25 5.99984C8.25 5.90234 8.2725 5.80484 8.31 5.71484C8.3475 5.61734 8.4 5.54234 8.4675 5.46734C8.5425 5.39984 8.625 5.34734 8.715 5.30984C8.805 5.27234 8.9025 5.24984 9 5.24984Z" fill="#7A28A0"/>
                        </svg> Show disclaimer
                    </a>
                </div>
            </div>
        </div>
    </div>
    <span class="shape-1"></span>
    <span class="shape-2"></span>
    <span class="shape-3"></span>
</section>

<!-- The howtoUseModal -->
<div class="modal aiContentModal" id="howtoUseModal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content border-radius16">
        <div class="modal-body howto-use">
            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <path d="M21.825 7.82463C22.2806 7.36902 22.2806 6.63033 21.825 6.17472C21.3694 5.7191 20.6307 5.7191 20.1751 6.17472L14 12.3498L7.825 6.17472C7.36939 5.7191 6.63069 5.7191 6.17508 6.17472C5.71947 6.63033 5.71947 7.36902 6.17508 7.82463L12.3501 13.9997L6.17508 20.1747C5.71947 20.6303 5.71947 21.369 6.17508 21.8246C6.63069 22.2802 7.36939 22.2802 7.825 21.8246L14 15.6496L20.1751 21.8246C20.6307 22.2802 21.3694 22.2802 21.825 21.8246C22.2806 21.369 22.2806 20.6303 21.825 20.1747L15.65 13.9997L21.825 7.82463Z" fill="#414E62"/>
                </svg>
            </button>
            <h4>Steps to use:</h4>
            <div class="row match-box">
                <div class="col-lg-4 col-md-12 dflex no-gutter">
                    <div class="useCard c-card">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M4.61763 16.2666C4.1093 16.2666 3.6343 16.0916 3.29263 15.7666C2.8593 15.3583 2.65097 14.7416 2.72597 14.075L3.0343 11.375C3.09263 10.8666 3.40097 10.1916 3.7593 9.82496L10.601 2.58329C12.3093 0.774959 14.0926 0.72496 15.901 2.43329C17.7093 4.14163 17.7593 5.92496 16.051 7.73329L9.2093 14.975C8.8593 15.35 8.2093 15.7 7.70096 15.7833L5.01763 16.2416C4.87597 16.25 4.75097 16.2666 4.61763 16.2666ZM13.276 2.42496C12.6343 2.42496 12.076 2.82496 11.5093 3.42496L4.66763 10.675C4.50097 10.85 4.3093 11.2666 4.27597 11.5083L3.96763 14.2083C3.9343 14.4833 4.00097 14.7083 4.15097 14.85C4.30097 14.9916 4.52597 15.0416 4.80097 15L7.4843 14.5416C7.72597 14.5 8.12597 14.2833 8.29263 14.1083L15.1343 6.86663C16.1676 5.76663 16.5426 4.74996 15.0343 3.33329C14.3676 2.69163 13.7926 2.42496 13.276 2.42496Z" fill="#7A28A0"/>
                                <path d="M14.4497 9.12504C14.433 9.12504 14.408 9.12504 14.3914 9.12504C11.7914 8.8667 9.69971 6.8917 9.29971 4.30837C9.24971 3.9667 9.48305 3.65004 9.82471 3.5917C10.1664 3.5417 10.483 3.77504 10.5414 4.1167C10.858 6.13337 12.4914 7.68337 14.5247 7.88337C14.8664 7.9167 15.1164 8.22504 15.083 8.5667C15.0414 8.88337 14.7664 9.12504 14.4497 9.12504Z" fill="#7A28A0"/>
                                <path d="M17.5 18.958H2.5C2.15833 18.958 1.875 18.6747 1.875 18.333C1.875 17.9913 2.15833 17.708 2.5 17.708H17.5C17.8417 17.708 18.125 17.9913 18.125 18.333C18.125 18.6747 17.8417 18.958 17.5 18.958Z" fill="#7A28A0"/>
                            </svg>
                        </span>
                        <small>Step 1</small>
                        <h3>Paste/Type your text</h3>
                        <p>Paste/Type your text in the empty space provided. Our tool works best in English.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 dflex no-gutter">
                    <div class="useCard middleCard c-card">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                <path d="M13.7504 11.8711H7.33376C6.95792 11.8711 6.64626 11.5594 6.64626 11.1836C6.64626 10.8078 6.95792 10.4961 7.33376 10.4961H13.7504C14.1263 10.4961 14.4379 10.8078 14.4379 11.1836C14.4379 11.5594 14.1263 11.8711 13.7504 11.8711Z" fill="#7A28A0"/>
                                <path d="M11.3488 15.5371H7.33376C6.95792 15.5371 6.64626 15.2254 6.64626 14.8496C6.64626 14.4738 6.95792 14.1621 7.33376 14.1621H11.3488C11.7246 14.1621 12.0363 14.4738 12.0363 14.8496C12.0363 15.2254 11.7246 15.5371 11.3488 15.5371Z" fill="#7A28A0"/>
                                <path d="M12.8338 6.18717H9.16709C8.28709 6.18717 6.64626 6.18717 6.64626 3.66634C6.64626 1.14551 8.28709 1.14551 9.16709 1.14551H12.8338C13.7138 1.14551 15.3546 1.14551 15.3546 3.66634C15.3546 4.54634 15.3546 6.18717 12.8338 6.18717ZM9.16709 2.52051C8.25959 2.52051 8.02126 2.52051 8.02126 3.66634C8.02126 4.81217 8.25959 4.81217 9.16709 4.81217H12.8338C13.9796 4.81217 13.9796 4.57384 13.9796 3.66634C13.9796 2.52051 13.7413 2.52051 12.8338 2.52051H9.16709Z" fill="#7A28A0"/>
                                <path d="M13.75 20.8536H8.25C3.09833 20.8536 2.0625 18.4886 2.0625 14.6661V9.16608C2.0625 4.98608 3.575 3.19858 7.29667 3.00608C7.66333 2.98774 8.0025 3.27191 8.02083 3.65691C8.03917 4.04191 7.74583 4.35358 7.37 4.37191C4.76667 4.51858 3.4375 5.29774 3.4375 9.16608V14.6661C3.4375 18.0577 4.10667 19.4786 8.25 19.4786H13.75C17.8933 19.4786 18.5625 18.0577 18.5625 14.6661V9.16608C18.5625 5.29774 17.2333 4.51858 14.63 4.37191C14.2542 4.35358 13.9608 4.02358 13.9792 3.64774C13.9975 3.27191 14.3275 2.97858 14.7033 2.99691C18.425 3.19858 19.9375 4.98607 19.9375 9.15691V14.6569C19.9375 18.4886 18.9017 20.8536 13.75 20.8536Z" fill="#7A28A0"/>
                            </svg>
                        </span>
                        <small>Step 2</small>
                        <h3>Click on “Check”</h3>
                        <p>Click on the “Check” button to initiate the process for AI content detection.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 dflex no-gutter">
                    <div class="useCard c-card">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
                                <path d="M13.9567 20.8538H7.83333C4.4875 20.8538 2.5625 18.9288 2.5625 15.583V7.79134C2.5625 4.44551 4.4875 2.52051 7.83333 2.52051H15.1667C18.5125 2.52051 20.4375 4.44551 20.4375 7.79134V12.4938C20.4375 12.7597 20.2817 12.998 20.0525 13.1172C19.8142 13.2272 19.53 13.1996 19.3283 13.0346C17.88 11.8796 15.6525 11.8797 14.2042 13.0622C13.2142 13.8413 12.655 15.0055 12.655 16.2797C12.655 17.0314 12.8567 17.7647 13.2508 18.3972C13.5533 18.8922 13.9292 19.3047 14.3692 19.6164C14.6075 19.7905 14.7175 20.1022 14.6258 20.3863C14.5158 20.6613 14.2592 20.8538 13.9567 20.8538ZM7.83333 3.89551C5.21167 3.89551 3.9375 5.16967 3.9375 7.79134V15.583C3.9375 18.2047 5.21167 19.4788 7.83333 19.4788H12.3158C12.2242 19.3597 12.1417 19.2314 12.0683 19.103C11.5458 18.2597 11.2708 17.2788 11.2708 16.2705C11.2708 14.5747 12.0225 13.0163 13.3425 11.9805C14.91 10.7063 17.2292 10.4313 19.0625 11.2747V7.79134C19.0625 5.16967 17.7883 3.89551 15.1667 3.89551H7.83333Z" fill="#7A28A0"/>
                                <path d="M16.7715 21.7713C15.5982 21.7713 14.489 21.4046 13.554 20.7263C12.9673 20.3046 12.4631 19.7547 12.069 19.1038C11.5465 18.2605 11.2715 17.2796 11.2715 16.2713C11.2715 14.5755 12.0232 13.0171 13.3432 11.9813C15.2498 10.4321 18.2565 10.4229 20.1815 11.9538C21.5107 12.9988 22.2715 14.5755 22.2715 16.2713C22.2715 17.2796 21.9965 18.2605 21.474 19.1038C21.1807 19.608 20.8048 20.048 20.374 20.4055C19.3931 21.2855 18.1098 21.7713 16.7715 21.7713ZM16.7715 12.1463C15.8365 12.1463 14.9198 12.4671 14.1957 13.0538C13.2057 13.833 12.6465 14.9971 12.6465 16.2713C12.6465 17.023 12.8481 17.7563 13.2423 18.3888C13.5448 18.8838 13.9206 19.2963 14.3606 19.608C15.0665 20.1213 15.9007 20.3963 16.7715 20.3963C17.789 20.3963 18.724 20.0388 19.4757 19.3696C19.8057 19.0946 20.0715 18.7738 20.2915 18.398C20.6856 17.7563 20.8965 17.023 20.8965 16.2713C20.8965 14.9971 20.3282 13.8238 19.329 13.0354C18.5957 12.4488 17.7157 12.1463 16.7715 12.1463Z" fill="#7A28A0"/>
                                <path d="M16.7702 19.2503C16.3943 19.2503 16.0827 18.9387 16.0827 18.5628C16.0827 17.6828 15.3585 16.9587 14.4785 16.9587C14.1027 16.9587 13.791 16.647 13.791 16.2712C13.791 15.8953 14.1027 15.5837 14.4785 15.5837C15.3585 15.5837 16.0827 14.8595 16.0827 13.9795C16.0827 13.6037 16.3943 13.292 16.7702 13.292C17.146 13.292 17.4577 13.6037 17.4577 13.9795C17.4577 14.8595 18.1818 15.5837 19.0618 15.5837C19.4377 15.5837 19.7493 15.8953 19.7493 16.2712C19.7493 16.647 19.4377 16.9587 19.0618 16.9587C18.1818 16.9587 17.4577 17.6828 17.4577 18.5628C17.4577 18.9387 17.146 19.2503 16.7702 19.2503ZM16.376 16.2712C16.5135 16.3903 16.651 16.5187 16.7702 16.6653C16.8893 16.5278 17.0177 16.3903 17.1643 16.2712C17.0268 16.152 16.8893 16.0237 16.7702 15.877C16.651 16.0237 16.5227 16.152 16.376 16.2712Z" fill="#7A28A0"/>
                                <path d="M6.1476 8.60159L7.55267 10.0722L10.5001 7.47511" stroke="#7A28A0" stroke-width="0.9"/>
                                <path d="M6.1476 13.6016L7.55267 15.0722L10.5001 12.4751" stroke="#7A28A0" stroke-width="0.9"/>
                            </svg>
                        </span>
                        <small>Step 3</small>
                        <h3>Get Results</h3>
                        <p>You will get a result which can be -”AI Generated” or “Human Generated” along with a score. </p>
                    </div>
                </div>
            </div>
            <!-- <div class="closeButton">
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close">
                    Close
                </button>
            </div> -->
        </div>
      </div>
    </div>
</div>

<!-- The DisclaimerModal -->
<div class="modal aiContentModal" id="disclaimerModal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content border-radius16">
        <div class="modal-body modalContent">
            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <path d="M21.825 7.82463C22.2806 7.36902 22.2806 6.63033 21.825 6.17472C21.3694 5.7191 20.6307 5.7191 20.1751 6.17472L14 12.3498L7.825 6.17472C7.36939 5.7191 6.63069 5.7191 6.17508 6.17472C5.71947 6.63033 5.71947 7.36902 6.17508 7.82463L12.3501 13.9997L6.17508 20.1747C5.71947 20.6303 5.71947 21.369 6.17508 21.8246C6.63069 22.2802 7.36939 22.2802 7.825 21.8246L14 15.6496L20.1751 21.8246C20.6307 22.2802 21.3694 22.2802 21.825 21.8246C22.2806 21.369 22.2806 20.6303 21.825 20.1747L15.65 13.9997L21.825 7.82463Z" fill="#414E62"/>
                </svg>
            </button>
            <h4>Disclaimer</h4>
            <p>Our AI-generated content detector is designed to assist in identifying content that may have been produced by an AI generator. However, this evaluation might not always accurately distinguish between human and AI-created content, so it should not be the sole factor in determining the nature of the content. You should thoroughly examine the content and rely on both personal judgment and the organization's policies to make the final decision.</p>
        </div>
      </div>
    </div>
</div>

<div class="brandLogo_sections">
    <div class="container">
        <div class="row">
            <div class="col-xl-2">
                <h2>Trusted by Global Leaders</h2>
            </div>
            <div class="col-xl-10">
                <div class="cover_wrapper">
                    <div id="client-logos-enterprise" class="owl-carousel">
                        <div class="item">
                            <div class="client-inners">
                                <img src="../../../assets/images/client_logos/HarvardMedicalSchool.png" alt="harvard medical school" width="180">
                            </div>
                        </div>

                        <div class="item">
                            <div class="client-inners">
                                <img src="../../../assets/images/client_logos/StanfordUniversity.png" alt="stanford university" width="180">
                            </div>
                        </div>
                        <div class="item">
                            <div class="client-inners">
                                <img src="../../../assets/images/client_logos/PrincetonUniversity.png" alt="pricnceton universities" width="180">
                            </div>
                        </div>
                        <div class="item">
                            <div class="client-inners">
                                <img src="../../../assets/images/client_logos/Caltech.png" alt="caltech universities" width="180">
                            </div>
                        </div>
                        <div class="item">
                            <div class="client-inners">
                                <img src="../../../assets/images/client_logos/MIT.png" alt="mit" width="180">
                            </div>
                        </div>
                        <div class="item">
                            <div class="client-inners">
                                <img src="../../../assets/images/client_logos/Yale.png" alt="yale universities" width="180">
                            </div>
                        </div>
                        <div class="item">
                            <div class="client-inners">
                                <img src="../../../assets/images/client_logos/Yonsei_University.png" alt="yonsei university" width="180">
                            </div>
                        </div>
                        <div class="item">
                            <div class="client-inners">
                                <img src="../../../assets/images/client_logos/nippon-institute.png" alt="university of tokyo" width="180">
                            </div>
                        </div>
                        <div class="item">
                            <div class="client-inners">
                                <img src="../../../assets/images/oxford.png" alt="oxford universities" width="180">
                            </div>
                        </div>
                        <div class="item">
                            <div class="client-inners">
                                <img src="../../../assets/images/cambridge.png" alt="cambridge universities" width="180">
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="section-pd list-wrapper-style-2">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-8">
                <div class="trinka-title text-center">
                    <h2>AI Generated Content Detector for Enterprises</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="list-wrapper-content-2 pr-80" style="padding: 0;">
                    <p class="mb-10">We understand that enterprises require a level of precision and insight that goes beyond standard content evaluation. That's why we offer the Enterprise version of our AI Content Detector Technology—an advanced solution over the basic features tailored to meet the unique demands of your organization.</p>
                    <p class="mb-10">Our advanced system offers a detailed analysis of your content, shedding light on elements crafted by humans and those generated by AI, offering insights down to the sentence and phrase level. </p>
                    <p class="mb-10">With Trinka AI Content Detection for Enterprises, you're equipped with the essential technology to review integrity of any text to the highest standards.</p>
                    <p class="mb-10">Know more about how Trinka AI Content Detector can benefit your enterprise.</p>
                    <div class="btn-group">
                        <button (click)="sectionValue('Enterprise Form')" id="salesContactButtons" data-toggle="modal"
                            data-target="#EnterPriseContactPopup" class="filled-btn" type="submit">Contact Us</button>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="list-wrapper-image">
                    <img src="../../../assets/images/AI-Generated-Content-Detector-for-Enterprises.svg" alt="ai content detector" class="img-fluid" />
                </div>
            </div>
            
        </div>
    </div>
</div>

<section class="section-purple section-pd">
    <div class="list-wrapper-style-2">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-11 col-lg-10">
                    <div class="trinka-title text-center">
                        <h2>Why Choose Trinka's AI Content Detector</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
            <div class="row flex-row-reverse align-items-center">
                <div class="col-lg-6">
                    <div class="list-wrapper-content-2" style="padding: 0;">
                        <ul class="list-box">
                            <li><b>Wide Coverage of LLM Models:</b> Detects AI-generated text from LLMs such as ChatGPT, GPT-4, Bard, Bing Chat, Claude.</li>
                            <li><b>Swift and Precise Analysis:</b> Get instant results with high accuracy.</li>
                            <li><b>Paraphrased AI Content Detection:</b> Identifies AI-generated text, even when it's paraphrased.</li>
                            <li><b>Quick Overview with Score:</b> Get a score indicating the proportion of AI-generated content in your text</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="list-wrapper-image">
                        <img src="../../../assets/images/why-choose-Trinka -AI-Content-Detector.svg" alt="ai content detector" class="img-fluid" />
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <div class="list-wrapper-style-2 section-pd pb-0">
        <div class="trinka_container">
            <div class="row justify-content-center align-items-center">
                <div class="col-md-10 col-lg-8">
                    <div class="trinka-title text-center">
                        <h2>Who Can Benefit from Trinka's AI Content Detector</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="list-wrapper-image">
                        <img src="../../../assets/images/benefit-from-trinka-AI-content.svg" alt="ai content detector" class="img-fluid" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="list-wrapper-content-2 mt-30 pr-80" style="padding: 0;">
                        <ul class="list-box">
                            <li><b>For Writers:</b> Search engines like Google can easily detect AI-generated content and potentially penalize it in their rankings. Stay one step ahead of your competition by ensuring your content is original and crafted by human hands.</li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="list-wrapper-content-2 mt-30 pr-80" style="padding: 0;">
                        <ul class="list-box">
                            <li><b>For Education:</b> Ensure that the content you encounter is human-created and aligns with your institute's guidelines. Our tool provides the clarity needed to verify authenticity.</li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="list-wrapper-content-2 mt-30 pr-80" style="padding: 0;">
                        <ul class="list-box">
                            <li><b>For Publishers:</b> Verify the work of your writers and collaborators to ensure it is authentically human-crafted. Guarantee the originality and integrity of your content before publication.</li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="list-wrapper-content-2 mt-30 pr-80" style="padding: 0;">
                        <ul class="list-box">
                            <li><b>For Researchers:</b> Ensure the reliability and authenticity of your reference materials and academic sources, safeguarding the integrity of your research and publications.</li>
                        </ul>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</section>

<div class="trinka-banner section-pd-sm">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-8">
                <div class="trinka-title text-center">
                    <h3 class="text-white">Looking to Improve Overall Content Quality?</h3>
                </div>
                <div class="btn-group justify-content-center">
                    <a href="https://cloud.trinka.ai/signup?_rg=GB" class="white-btn" target="_blank">Try Now</a>
                </div>
            </div>
        </div>  
    </div>
</div>

<div class="faqQuestionAnwser section-pd" #target>
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-10">
                <div class="trinka-title text-center">
                    <h2>Trinka’s AI Content Detector FAQ</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                </div>
            </div>
        </div>
        <div class="faqQuestionsList">
            <div class="accordion" id="faq">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="faqContainer">
                            <div class="card">
                                <div class="card-header" id="QuestionGCOne">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCOneContent" aria-expanded="true"
                                        aria-controls="QuestionGCOneContent">
                                        What is an AI content detector?</a>
                                </div>

                                <div id="QuestionGCOneContent" class="collapse"
                                    aria-labelledby="QuestionGCOne" data-parent="#faq">
                                    <div class="card-body">
                                        <p>An AI content detector is a specialized software designed for AI writing text detection. It determines whether a given piece of text was authored by an AI text generation tool like ChatGPT or by a human writer, thus providing clarity on the authenticity of the text.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCTwo">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCTwoContent" aria-expanded="true"
                                        aria-controls="QuestionGCTwoContent">How do AI detectors work?</a>
                                </div>

                                <div id="QuestionGCTwoContent" class="collapse"
                                    aria-labelledby="QuestionGCTwo" data-parent="#faq">
                                    <div class="card-body">
                                        <p>AI content detectors utilize natural language processing, machine learning, and deep learning techniques to analyze text. They discern the distinctions between human and AI writing, make predictions about text authorship, assign a likelihood score for human or AI origin, and continuously enhance their capabilities through updated training data.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCThree">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCThreeContent" aria-expanded="true"
                                        aria-controls="QuestionGCThreeContent">What's the difference between Plagiarism Detection and AI detection?</a>
                                </div>

                                <div id="QuestionGCThreeContent" class="collapse"
                                    aria-labelledby="QuestionGCThree" data-parent="#faq">
                                    <div class="card-body">
                                        <p><a [routerLink]="['/features/plagiarism-check']">Plagiarism Detection</a> primarily focuses on identifying instances of plagiarism, which involves the use of someone else's work, ideas, or content without proper attribution or permission. It seeks to ensure that content remains original and respects intellectual property rights. In contrast, AI writing text detection specifically determines whether a given piece of text was authored by a human or generated by an artificial intelligence system. Its goal is to verify the origin of content, whether it's human-crafted or AI-generated, offering insights into its authenticity. While both types of detection aim to maintain the integrity of content, they address different aspects of content assessment and originality.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCFour">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCFourContent" aria-expanded="true"
                                        aria-controls="QuestionGCFourContent">Can Educators detect if you used an AI assistant like ChatGPT for submissions?</a>
                                </div>

                                <div id="QuestionGCFourContent" class="collapse"
                                    aria-labelledby="QuestionGCFour" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Educators have methods to detect AI involvement in student assignments. In addition to unnatural language, inconsistencies, or AI detection tools, they may observe inconsistencies or lack of depth. Students should avoid relying on AI for Academic submissions, which could be perceived as academic dishonesty.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="faqContainer">
                            <div class="card">
                                <div class="card-header" id="QuestionGCFive">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCFiveContent" aria-expanded="true"
                                        aria-controls="QuestionGCFiveContent">Are AI detection tools accurate?</a>
                                </div>

                                <div id="QuestionGCFiveContent" class="collapse"
                                    aria-labelledby="QuestionGCFive" data-parent="#faq">
                                    <div class="card-body">
                                        <p>The accuracy of AI content detectors can vary. Trinka AI Content Detector, for example, delivers a robust performance, with a high level of precision in identifying AI-generated content for sources like ChatGPT, GPT-4, Bard, and other leading text generation tools. As technology continues to evolve, Trinka AI Content Detector strives to further enhance its accuracy, ensuring the reliability and authenticity of online information.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCSix">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCSixContent" aria-expanded="true"
                                        aria-controls="QuestionGCSixContent">Does Google detect AI content?</a>
                                </div>

                                <div id="QuestionGCSixContent" class="collapse"
                                    aria-labelledby="QuestionGCSix" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Search engines like Google are actively working to improve their algorithms to detect AI-generated content and address concerns about its impact on SEO. While AI-generated content has raised concerns about producing low-quality spam, search engines are adapting to identify and manage such content effectively.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCSeven">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCSevenContent" aria-expanded="true"
                                        aria-controls="QuestionGCSevenContent">Can Trinka detect mixed text where human-created text has been amended with AI-generated text?</a>
                                </div>

                                <div id="QuestionGCSevenContent" class="collapse"
                                    aria-labelledby="QuestionGCSeven" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Absolutely. Our detection report provides insights into specific sections of the text authored by humans and those generated by AI. It includes a confidence level, offering a transparent breakdown of content origin. However, this feature is exclusively available in the Enterprise Version. Contact us to know more. 
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCEight">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCEightContent" aria-expanded="true"
                                        aria-controls="QuestionGCEightContent">Is Trinka AI Content Detector Free?</a>
                                </div>

                                <div id="QuestionGCEightContent" class="collapse"
                                    aria-labelledby="QuestionGCEight" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Trinka's AI Content Detector is available for free with certain limitations. Users can enjoy up to 10 sessions per day, with each session allowing analysis of up to 500 words. This provides an opportunity for unlimited content review, ensuring accuracy and authenticity.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal custom_modal_popup enterprisePopup" id="EnterPriseContactPopup">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-body">
                <div class="closed_icons">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="contactPopupHeading">
                            <h2>Enterprise Inquiry</h2>
                            <p *ngIf="formSection">Whom are you inquiring for?</p>
                        </div>
                    </div>
                    <div class="inquiryTypeSection">
                        <ul class="nav nav-tabs" role="tablist" *ngIf="formSection">
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#IndividualType" role="tab">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                                        <defs>
                                            <style>
                                                .cls-1 {
                                                    fill: none;
                                                    stroke: #783694;
                                                    stroke-linecap: round;
                                                    stroke-linejoin: round;
                                                    stroke-width: 2px;
                                                }
                                            </style>
                                        </defs>
                                        <title>TrinkaIcons-new</title>
                                        <g id="Individual">
                                            <path class="cls-1" d="M44.83,45.13V39.82a4.65,4.65,0,0,0-1.7-3.6,62.85,62.85,0,0,0-10.89-7V22.5a4.4,4.4,0,0,0,1.56-3.36V11.39A7.9,7.9,0,0,0,25.91,3.5H24.24a7.9,7.9,0,0,0-7.89,7.89v7.75a4.32,4.32,0,0,0,1.54,3.36h0v6.68A62.22,62.22,0,0,0,7,36.22a4.62,4.62,0,0,0-1.7,3.6v5.31"
                                            />
                                        </g>
                                    </svg>
                                    <div class="text-left">
                                        <h3>Individual</h3>
                                        <span>(Single user)</span>
                                    </div>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#OrganizationType" role="tab">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                                        <defs>
                                            <style>
                                                .cls-1 {
                                                    fill: none;
                                                    stroke: #783694;
                                                    stroke-linecap: round;
                                                    stroke-linejoin: round;
                                                    stroke-width: 2px;
                                                }
                                            </style>
                                        </defs>
                                        <title>TrinkaIcons-new</title>
                                        <g id="Organization">
                                            <path class="cls-1" d="M42.87,45.13V40.06a4.45,4.45,0,0,0-1.62-3.44,60,60,0,0,0-10.41-6.72V23.51a4.2,4.2,0,0,0,1.49-3.21V12.89a7.55,7.55,0,0,0-7.54-7.54H23.19a7.55,7.55,0,0,0-7.54,7.54V20.3a4.13,4.13,0,0,0,1.47,3.21h0v6.38A59.44,59.44,0,0,0,6.71,36.62a4.42,4.42,0,0,0-1.62,3.44v5.07"
                                            />
                                            <path class="cls-1" d="M14.34,30.75a24.56,24.56,0,0,0-3.32-2V26.18a1.66,1.66,0,0,0,.59-1.27V22a3,3,0,0,0-3-3H8a3,3,0,0,0-3,3v2.93a1.63,1.63,0,0,0,.58,1.27h0v2.53a23.54,23.54,0,0,0-4.12,2.66,1.75,1.75,0,0,0-.64,1.36v2"
                                            />
                                            <path class="cls-1" d="M34.29,30.75a24.56,24.56,0,0,1,3.32-2V26.18A1.66,1.66,0,0,1,37,24.91V22a3,3,0,0,1,3-3h.63a3,3,0,0,1,3,3v2.93A1.63,1.63,0,0,1,43,26.18h0v2.53a23.54,23.54,0,0,1,4.12,2.66,1.75,1.75,0,0,1,.64,1.36v2"
                                            />
                                        </g>
                                    </svg>
                                    <div class="text-left">
                                        <h3>Organization</h3>
                                        <span>(Multiple users)</span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane" id="IndividualType" role="tabpanel" *ngIf="formSection">
                                <div class="IndividualTypeSection">
                                    <p>Trinka is free for individual users.
                                        <br /> You can simply register with your email address.</p>
                                    <a href="https://cloud.trinka.ai/signup" target="_blank">Register for Free</a>
                                </div>
                            </div>
                            <div class="tab-pane" id="OrganizationType" role="tabpanel" *ngIf="formSection">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="contact_us_form">
                                            <form [formGroup]="contactForm" (ngSubmit)="sendContactUsForm()">
                                                <h2>Share your requirements with us and our team will respond to you promptly.</h2>
                                                <div class="row">
                                                    <div class="col-md-6 rightPaddingRemove">
                                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('NatureBusinessType').valid && contactForm.get('NatureBusinessType').dirty) || (contactForm.get('NatureBusinessType').touched && contactForm.get('NatureBusinessType').pristine) ? 'TextFiledError' : '' ">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Nature of business*</mat-label>
                                                                <input matInput placeholder="" formControlName="NatureBusinessType" class="form-control">
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 leftPaddingRemove">
                                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('contactFormSubjects').valid && contactForm.get('contactFormSubjects').dirty) || (contactForm.get('contactFormSubjects').touched && contactForm.get('contactFormSubjects').pristine) ? 'TextFiledError' : '' ">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Organization name*</mat-label>
                                                                <input matInput placeholder="" formControlName="contactFormSubjects" class="form-control">
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="textbox_content form-group textareaBox" [ngClass]="(!contactForm.get('contactFormMessage').valid && contactForm.get('contactFormMessage').dirty) || (contactForm.get('contactFormMessage').touched && contactForm.get('contactFormMessage').pristine) ? 'TextFiledError' : '' ">
                                                            <label for="">Tell us a bit about your requirement</label>
                                                            <textarea formControlName="contactFormMessage" class="form-control" placeholder="E.g. approximare number of users, monthly word count volume per user, customizations needed, etc."></textarea>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6 rightPaddingRemove">
                                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('contactFormName').valid && contactForm.get('contactFormName').dirty) || (contactForm.get('contactFormName').touched && contactForm.get('contactFormName').pristine) ? 'TextFiledError' : '' ">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Name*</mat-label>
                                                                <input matInput placeholder="" formControlName="contactFormName" class="form-control">
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 leftPaddingRemove">
                                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('DesignationTypes').valid && contactForm.get('DesignationTypes').dirty) || (contactForm.get('DesignationTypes').touched && contactForm.get('DesignationTypes').pristine) ? 'TextFiledError' : '' ">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Designation*</mat-label>
                                                                <input matInput placeholder="" formControlName="DesignationTypes" class="form-control">
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 rightPaddingRemove">
                                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('contactFormEmail').valid && contactForm.get('contactFormEmail').dirty) || (contactForm.get('contactFormEmail').touched && contactForm.get('contactFormEmail').pristine) ? 'TextFiledError' : '' ">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Email*</mat-label>
                                                                <input matInput placeholder="" formControlName="contactFormEmail" class="form-control">
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 leftPaddingRemove">
                                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('contactphoneNo').valid && contactForm.get('contactphoneNo').dirty) || (contactForm.get('contactphoneNo').touched && contactForm.get('contactphoneNo').pristine) ? 'TextFiledError' : '' ">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Contact Number* (incl. ISD code)</mat-label>
                                                                <input type="number" matInput placeholder="" formControlName="contactphoneNo" class="form-control">
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group text-center">
                                                    <button type="submit" [disabled]="isSubmitting">Submit</button>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="thank_section contact_us_form" *ngIf="thankSection">
                                <div class="form-group">
                                    <h2>Thank You!</h2>
                                    <p>We will contact you soon.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>