import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject, Renderer2 } from '@angular/core';
import { isPlatformBrowser, isPlatformServer, DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import * as $ from 'jquery';
import { LinkService } from '../../../_services/langhfre.service';
declare function ire(string,{}): any;
@Component({
  selector: 'app-consistency-checks',
  templateUrl: './consistency-checks.component.html',
  styleUrls: ['./consistency-checks.component.scss']
})
export class ConsistencyChecksComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private LinkService: LinkService
  ) { }

  ngOnInit() {
    this.titleService.setTitle("Fix inconsistencies in your writing with Trinka's Consistency Check");
    this.metaTagService.updateTag({ name: 'description', content: "Save time and give your writing a professional finish in just a few clicks with Trinka's Consistency Check" });
    this.metaTagService.updateTag({ property: 'og:title', content:"Fix inconsistencies in your writing with Trinka's Consistency Check" });
    this.metaTagService.updateTag({ property: 'og:description', content: "Save time and give your writing a professional finish in just a few clicks with Trinka's Consistency Check" });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/features/consistency-check' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/Consistency-Checks.png' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: "Fix inconsistencies in your writing with Trinka's Consistency Check" });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Save time and give your writing a professional finish in just a few clicks with Trinka's Consistency Check" });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/Consistency-Checks.png' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/features/consistency-check' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/features/consistency-check' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/features/consistency-check' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/features/consistency-check' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/features/consistency-check' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/features/consistency-check' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/features/consistency-check' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/features/consistency-check' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/features/consistency-check' });
    if (isPlatformBrowser(this.platformId)) {
      // $.getScript('../../../assets/js/particles.min.js', function(){});
      //$.getScript('../../../assets/js/wisepop.js', function () { });
    }
    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `
    {"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Trinka AI","item":"https://www.trinka.ai/"},{"@type":"ListItem","position":2,"name":"Trinka Features","item":"https://www.trinka.ai/features/"},{"@type":"ListItem","position":3,"name":"Consistency Check","item":"https://www.trinka.ai/features/consistency-check"}]}
    `;

    this._renderer2.appendChild(this._document.head, script);

    let script1 = this._renderer2.createElement('script');
    script1.type = `application/ld+json`;
    script1.text = `
    {"@context":"https://schema.org","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"How does Trinka's Consistency Check work?","acceptedAnswer":{"@type":"Answer","text":"Trinka’s Consistency Check automatically finds variations in several common inconsistencies made by writers and lists them all for you. It also gives you correction options. All you need to do is choose the right style and confirm. Trinka does the rest."}},{"@type":"Question","name":"Where can I find the Consistency Check feature?","acceptedAnswer":{"@type":"Answer","text":"The Consistency Check feature is currently available on the Cloud editor to all users."}},{"@type":"Question","name":"Does the Consistency Check feature require credits?","acceptedAnswer":{"@type":"Answer","text":"Currently, no credits are required to use the Consistency Check feature. You can use the feature as many times as you like."}},{"@type":"Question","name":"When should I use the Consistency Check feature?","acceptedAnswer":{"@type":"Answer","text":"Ideally, you should run the Consistency Check feature after you have finished writing. However, you can run it as many times and whenever you see the need for it."}}]}
    `;
    this._renderer2.appendChild(this._document.head, script1);

    let script2 = this._renderer2.createElement('script');
    script2.type = `application/ld+json`;
    script2.text = `
    {"@context":"https://schema.org","@type":"VideoObject","name":"Trinka AI Consistency Checker Walkthrough: A Step-by-Step Guide for Academic Writers","description":"In this video, we will take you on a walkthrough of Trinka AI Consistency Checker, a powerful tool that helps academic writers ensure data accuracy in their ...","thumbnailUrl":"https://i.ytimg.com/vi/hTaMLLHICCo/mqdefault.jpg","duration":"PT53S","contentUrl":"https://www.youtube.com/watch?v=hTaMLLHICCo","embedUrl":"https://www.youtube.com/embed/hTaMLLHICCo","uploadDate":"2021-03-21","potentialAction":{"@type":"SeekToAction","target":"https://youtu.be/hTaMLLHICCo?t={seek_to_second_number}","startOffset-input":"required name=seek_to_second_number"}}
    `;
    this._renderer2.appendChild(this._document.head, script2);
    ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      $.getScript('../../../../assets/js/home_client_slider.js', function () { });
      $('#closedPopup, #wordPluginPopup').click(function () {
        $('iframe.youtubeVideoForTryDemo').remove()
      });

      $('#VewiVideoSection').click(function () {
        if (!$('#iframe').length) {
          $('#iframeHolder').html('<iframe class="youtubeVideoForTryDemo" id="iframe" src="https://www.youtube.com/embed/hTaMLLHICCo" width="100%" height="450"></iframe>');
        }
      });

    }
  }

  
}
